.container-controlbar {
    display: flex;
    justify-content: flex-start;

    width: 1700px;
    height: 132px;
}

.current-music {
    display: flex;
    flex-direction: column;

    width: 220px;

    margin: 24px 0 0 32px;
}

.current-music h3 {
    font-family: 'Roboto', sans-serif;
    font-size: 24px;
    font-weight: 700;

    color: #ffffff;
}

.current-music span {
    margin-top: 8px;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 700;

    color: #ffffff;
}

.control {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
}

.buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;

    margin: 5px 90px 0 0;

    cursor: pointer;
}

.buttons img:hover {
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.08);
}

.progressbar {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 20px;
    margin: 12px;
}

.progressbar span {
    margin: 0px 8px;

    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 700;

    color: #ccc3c3;
}

.slider {
    -webkit-appearance: none;
    width: 560px;
    height: 4px;

    cursor: pointer;
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #e5007b;
    cursor: pointer;
}

.volume {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;

    width: 220px;
}

.volume img {
    width: 40px;

    cursor: pointer;
}

.volume-bar {
    width: 100px;

    cursor: pointer;
}
