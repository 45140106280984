.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100vw;
    height: 100vh;
    background-image: linear-gradient(180deg, #0c0505 0%, #3a3535 47.92%, #5e5858 100%);
}

.header {
    display: flex;
    justify-content: center;

    width: 100vw;
    border-bottom: 1px solid #6a6363;
}

main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 1350px;
    height: calc(100vh - 132px - 97px);
}

.title-play-list {
    align-self: flex-start;
    margin: 50px 0 24px 0;

    font-family: 'Roboto', sans-serif;
    font-size: 24px;
    font-weight: 700;

    color: #ffffff;
}

.cards-musics {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    width: 1360px;

    border-top: 1px solid #6a6363;
}

.cards-musics:hover div:not(:hover) {
    opacity: 0.7;
}

.controlbar {
    display: flex;
    justify-content: center;

    width: 100vw;

    background-image: linear-gradient(180deg, #383535 0%, rgba(133, 130, 130, 0) 100%);
}
