.container-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 1700px;
    height: 97px;
}

.logo-image {
    width: 292px;
    height: 64px;
    margin-left: 32px;

    cursor: pointer;
}

.perfil {
    display: flex;
    justify-content: center;
    align-items: center;
}

.profile-image {
    width: 40px;
    height: 40px;

    border-radius: 50%;
    border: 2px solid #6a6363;

    cursor: pointer;
}

.perfil span {
    margin: 0 32px 0 8px;
    color: #fff;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 700;

    cursor: pointer;
}
