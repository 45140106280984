.container-card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    width: 320px;
    margin-top: 16px;

    cursor: pointer;
}

.container-card:hover {
    scale: 1.05;
}

.cover {
    width: 320px;
    height: 320px;
}

.title-music {
    margin: 8px 0;
    font-family: 'Rubik', sans-serif;
    font-size: 22px;
    font-weight: 700;

    color: #ffffff;
}

.description-music {
    text-align: justify;
    font-family: 'Rubik', sans-serif;
    font-size: 17px;
    font-weight: 400;

    color: #ccc3c3;
}
